<template>
  <v-navigation-drawer
    id="default-drawer"
    v-model="drawer"
    dark
    :mini-variant.sync="mini"
    mini-variant-width="80"
    app
    width="260"
  >
    <div class="px-2">
      <drawer-header />

      <v-divider class="mx-3 mb-2" />

      <list :items="leftMenuItems" />
    </div>

    <div class="pt-12" />
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { get, sync } from 'vuex-pathify'

export default {
  name: 'DefaultDrawer',

  components: {
    DrawerHeader: () =>
      import(
        /* webpackChunkName: "default-drawer-header" */
        './widgets/DrawerHeader'
      ),
    List: () =>
      import(
        /* webpackChunkName: "default-list" */
        './List'
      )
  },

  computed: {
    ...get('app', ['leftMenuItems']),
    ...sync('app', ['drawer', 'mini'])
  }
}
</script>

<style lang="sass">
#default-drawer
  .v-list-item
    margin-bottom: 8px

  .v-list-item::before,
  .v-list-item::after
    display: none

  .v-list-group__header__prepend-icon,
  .v-list-item__icon
    margin-top: 12px
    margin-bottom: 12px
    margin-left: 4px

  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
</style>
